<template>
  <el-dialog
    @close="closeDialog"
    title="是否通过审核"
    width="600px"
    :visible="examineVisible"
    :show-close="false"
  >
    <el-radio v-model="radio" label="1">通过</el-radio>
    <el-radio v-model="radio" label="0">拒绝</el-radio>
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="110px"
    >
      <el-form-item label="备注" prop="remark" style="width: 100%">
        <el-input type="textarea" v-model="ruleForm.message" placeholder="请输入备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="onSubmit('ruleForm', radio)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { api } from '/src/api/base';
export const projectCheckView = api()('project.check.view.json')
export default {
  props: {
    code: String,
    examineVisible: Boolean
  },
  data () {
    return {
      radio: '1',
      ruleForm: {
        message: ''
      },
      rules: {}
    }
  },

  methods: {
    closeDialog () {
      this.$emit('update:examineVisible', false);
      this.$emit('close', this.examineVisible);
    },
    onSubmit (formName, state) {
      console.log(state);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          projectCheckView({
            code: this.code,
            remark: this.ruleForm.message,
            state: state
          }).then(() => {
            this.closeDialog(formName);
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.$parent.loadData();

          });
        } else {
          return false;
        }
      });
    },
  }

}
</script>

<style>
</style>